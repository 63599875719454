<template>
  <div class="activity-container">
    <template v-if="route.query.noexit">
      <img src="@/assets/image/activity/noexit.png" class="activity-img" alt />
      <div>商品已下架</div>
    </template>
    <template v-else>
      <img src="@/assets/image/activity/success.png" class="activity-img" alt />
      <div>提交成功</div>
    </template>
    <!-- 按钮提交 -->
    <!-- <van-button block round class="submit" @click="win_close">{{route.query.noexit?'返回':'确定'}}</van-button> -->
  </div>
</template>

<script >
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRefs } from 'vue';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      code: '',
      msg: '',
    });
    const win_close = () => {
      // 判断是否支持WeixinJSBridge 微信环境
      if (typeof WeixinJSBridge !== 'undefined') {
        WeixinJSBridge.call('closeWindow'); // 微信自带的关闭窗口WeixinJSBridge.call("closeWindow")
      } else {
        // H5 环境
        if (navigator.userAgent.indexOf('MSIE') > 0) {
          if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
          } else {
            window.open('', '_top');
            window.top.close();
          }
        } else if (navigator.userAgent.indexOf('Firefox') > 0) {
          window.location.href = 'about:blank ';
        } else {
          window.opener = null;
          window.open('', '_self', '');
          window.close();
        }
      }
    };
    return {
      ...toRefs(state),
      router,
      route,
      win_close,
    };
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  background: #fafafa;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 350px;
  }
  .submit {
    background: linear-gradient(
      93.3deg,
      rgba(0, 87, 255, 1) 0%,
      rgba(0, 126, 255, 1) 100%
    );
    color: #fff;
    margin: 188px auto 0;
    width: 568px;
  }
  .error {
    color: #ff2f2f;
  }
}
</style>